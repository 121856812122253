'use client'

import { LogoutButton } from '~/components/logout-button'

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  console.error(`/error.tsx error occurred: ${error.message}`)

  return (
    <main className='grid min-h-screen place-items-center bg-white px-6 py-24 lg:px-8 sm:py-32'>
      <div className='text-center'>
        <p className='font-semibold text-base text-indigo-600'>죄송합니다.</p>
        <h1 className='mt-4 font-bold text-3xl text-gray-900 tracking-tight sm:text-5xl'>에러가 발생했습니다.</h1>
        <p className='mt-6 text-base text-gray-600 leading-7'>
          로그아웃하고 다시 로그인해주세요.
          <br />
          그럼에도 문제가 지속적으로 발생한다면, 관리자에게 문의해주세요.
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <LogoutButton />
          <a
            href='mailto:lxzin@typeit.ai'
            target='_blank'
            rel='noreferrer'
            className='font-semibold text-gray-900 text-sm'
          >
            문의하기 <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}
